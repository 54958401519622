<template>
  <div>
    <v-simple-table v-if="!!variations && variations.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              v-if="
                !!variations &&
                variations.length > 0 &&
                !!variations[0].attributes &&
                variations[0].attributes.length > 0
              "
            >
              {{ variations[0].attributes[0].name }}
            </th>
            <th
              class="text-left"
              v-if="
                !!variations &&
                variations.length > 0 &&
                !!variations[0].attributes &&
                variations[0].attributes.length > 1
              "
            >
              {{ variations[0].attributes[1].name }}
            </th>
            <th class="text-left">Stock</th>
            <th class="text-left">Efectivo</th>
            <th class="text-left">Tarjeta</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="variation in variations" :key="variation.id">
            <td
              v-if="!!variation.attributes && variation.attributes.length > 0"
            >
              {{ variation.attributes[0].option }}
            </td>
            <td
              v-if="!!variation.attributes && variation.attributes.length > 1"
            >
              {{ variation.attributes[1].option }}
            </td>
            <td>{{ variation.stock_quantity }}</td>
            <td>{{ variation.price }}</td>
            <td>{{ variation.regular_price }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "Variations",
  props: {
    variations: {},
  },
};
</script>