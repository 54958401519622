var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.variations && _vm.variations.length > 0)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(
              !!_vm.variations &&
              _vm.variations.length > 0 &&
              !!_vm.variations[0].attributes &&
              _vm.variations[0].attributes.length > 0
            )?_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.variations[0].attributes[0].name)+" ")]):_vm._e(),(
              !!_vm.variations &&
              _vm.variations.length > 0 &&
              !!_vm.variations[0].attributes &&
              _vm.variations[0].attributes.length > 1
            )?_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.variations[0].attributes[1].name)+" ")]):_vm._e(),_c('th',{staticClass:"text-left"},[_vm._v("Stock")]),_c('th',{staticClass:"text-left"},[_vm._v("Efectivo")]),_c('th',{staticClass:"text-left"},[_vm._v("Tarjeta")])])]),_c('tbody',_vm._l((_vm.variations),function(variation){return _c('tr',{key:variation.id},[(!!variation.attributes && variation.attributes.length > 0)?_c('td',[_vm._v(" "+_vm._s(variation.attributes[0].option)+" ")]):_vm._e(),(!!variation.attributes && variation.attributes.length > 1)?_c('td',[_vm._v(" "+_vm._s(variation.attributes[1].option)+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(variation.stock_quantity))]),_c('td',[_vm._v(_vm._s(variation.price))]),_c('td',[_vm._v(_vm._s(variation.regular_price))])])}),0)]},proxy:true}],null,false,820107400)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }